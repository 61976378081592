.video-blue-haxagon {
  padding: 1px;
  background-color: #2844c3;
  height: 100%;
}

.borderParallelogram .video-inner-bg {
  background: #05061e;
}

.borderParallelogram .video-inner-bg,
.borderParallelogram .video-inner,
.borderParallelogram .video-blue-haxagon {
  clip-path: polygon(20% 0%, 80% 0%, 100% 33%, 80% 100%, 20% 100%, 0 67%);
  -webkit-clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 33%,
    80% 100%,
    20% 100%,
    0 67%
  );
}

.borderParallelogram .video-inner {
  margin-top: 0px;
  background-color: #2844c326;
  padding: 0;
}

.borderParallelogram:after,
.borderParallelogram:before {
  content: "";
  position: absolute;
  background-color: #1f307e;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  width: 100%;
  height: calc(100% - 10px);
  border-radius: 0;
  transition: -webkit-clip-path 0.2s ease;
  -webkit-transition: -webkit-clip-path 0.2s ease;
  transition: clip-path 0.2s ease;
  transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
}

/* polygon(14% 15%, 60% 3%, 60% 3%, 15% 16%, 0% 100%, 0% 100%, 16.5% 0%) */

.borderParallelogram:before {
  top: -5px;
  left: 0px;
  clip-path: polygon(19.7% 0.5%, 41% 0%, 19.7% 0%, 0 68%);
  -webkit-clip-path: polygon(19.7% 0.5%, 41% 0%, 19.7% 0%, 0 68%);
}

.borderParallelogram:after {
  bottom: -6px;
  right: 0;
  clip-path: polygon(80.3% 99.3%, 94% 53%, 80.3% 100%, 43% 100%);
  -webkit-clip-path: polygon(80.3% 99.3%, 94% 53%, 80.3% 100%, 43% 100%);
}
