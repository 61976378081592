.banner {
    height               : 600px;
    background-size      : cover !important;
    background-position-x: center !important;
}

.home-banner {
    background: url('../../assets/img/comingsoon.png') no-repeat;
}

.about-banner {
    background: url('../../assets/img/about-banner.jpg') no-repeat;
}

.party {
    background: url('../../assets/img/school.jpg') no-repeat;
}

.gallery {
    background: url('../../assets/img/gallery.jpg') no-repeat;
}

.Cafe-banner {
    background: url('../../assets/img/Cafe.jpg') no-repeat;
}

.schooltrip {
    background: url('../../assets/img/school.jpg') no-repeat;
}

.trippackage {
    background: url('../../assets/img/bannertrip.jpg') no-repeat;
}
.cafe-image{
    background: url('../../assets/img/cafebanner.jpg') no-repeat;
}

.center {
    align-self: center;
}

.bottom {
    align-self: last baseline;
}

.slick-slide>div {
    width: 100%;
}

@media (max-width: 768px) {
   .banner.trippackage {
    background: url('../../assets/img/about-banner.jpg') no-repeat;
        background-size      : cover !important;
        background-position-x: center !important;
    }
    .banner{
        height: 400px;
    }
}